import ApplicationWrapper from '../wrappers/ApplicationWrapper';

function PageNotFound() {
  return (
    <ApplicationWrapper>
      <div id="content">
        <div className="page-404-section">
          <div className="container">
            <h1 className="title">404</h1>
            <p className="text-size-big">
              We're sorry, but we can't find the page you were looking for.
            </p>
            <p>
              It's probably some thing we've done wrong but now we know about it and{" "}
              <br />
              we'll try to fix it. Go{" "}
              <a href="#" className="link-text">
                Home
              </a>{" "}
              or try to search:
            </p>
            <form className="newsletter">
              <input type="email" name="newsletter-email" placeholder="Search" />
              <button
                type="submit"
                datatype="submit"
                className="btn btn-big btn-style-3"
              >
                Search
              </button>
            </form>
          </div>
        </div>
      </div>
    </ApplicationWrapper>
  );
}

export default PageNotFound;
