import { useState } from 'react';
import GeoMap from '../../../components/GeoMap/GeoMap';

function Contact() {

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const [responseMessage, setResponseMessage] = useState('');

  const triggerOnFormSubmit = async (e: { preventDefault: () => void; }) => {
    console.log(e);
    e.preventDefault();
    console.log('triggerOnFormSubmit');

    // Call backend with our three variables
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;

    const res = await fetch(`${ baseUrl }/contact-form`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        name,
        message,
      }),
    });

    console.log(res);

    if (res.status >= 200 && res.status < 300) {
      setResponseMessage('Uspesno ste poslali poruku.');
      // set all fields to empty
      setEmail('');
      setName('');
      setMessage('');
    } else {
      setResponseMessage('Doslo je do greske. Pokusajte ponovo.');
    }
  };

  return (
    <>
      {/* - - - - - - - - - - - - - Breadcrumbs - - - - - - - - - - - - - - - - */ }
      <div className="breadcrumbs-wrap">
        <div className="container">
          <h1 className="page-title">Kontaktirajte nas</h1>
          <ul className="breadcrumbs">
            <li>
              <a href="/">Početna</a>
            </li>
            <li>Kontaktirajte nas</li>
          </ul>
        </div>
      </div>
      <div id="content">
        <div className="map-section content-element10">
          <GeoMap/>
          <div className="container">
            <div className="map-info">
              <div className="row col-md-12 col-sm-12">
                <h4 className="title">Beograd</h4>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="our-info vr-type">
                    <div className="info-item">
                      <i className="licon-map-marker"/>
                      <span className="post">Adresa</span>
                      <h6>Internacionalnih brigada 25A, Beograd, Vračar 11000.</h6>
                      <a
                        href="https://www.google.com/maps/place/Gaja+Yoga+Studio/@44.7950293,20.4669304,17z/data=!3m1!4b1!4m6!3m5!1s0x475a7118c33511cf:0x649b9f967376ce86!8m2!3d44.7950293!4d20.4695053!16s%2Fg%2F11vf3f8xmc?entry=ttu"
                        className="link-text"
                        target={ '_blank'}
                      >
                        Otvorite u mapi
                      </a>
                    </div>
                    <div className="info-item">
                      <i className="licon-telephone"/>
                      <span className="post">Telefon</span>
                      <h6 content="telephone=no">+381 62 544 168</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="our-info vr-type">
                    <div className="info-item">
                      <i className="licon-at-sign"/>
                      <span className="post">Email Adresa</span>
                      <h6>info@gajayoga.rs</h6>
                    </div>
                    <div className="info-item">
                      <i className="licon-clock3"/>
                      <span className="post">Opening Hours</span>
                      <h6>
                        Ponedeljak - Petak: <br/> 7:00 - 21:00
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="page-section parallax-section text-color-light"
          data-bg="/images/1920x1000_bg2.jpg"
        >
          <div className="container">
            <div className="content-element5">
              <div className="section-pre-title">Imate pitanja?</div>
              <h2 className="section-title">Kontaktirajte nas</h2>
            </div>
            <p className="content-element3">
              Slobodno nam posaljite bilo koja pitanja vezana za yogu ili nas studio. Rado cemo vam odgovoriti.
            </p>
            <form id="contact-form" className="contact-form" onSubmit={ triggerOnFormSubmit }>
              <div className="contact-item">
                <label>Vaše ime</label>
                <input type="text" name="cf-name" required={ true } value={ name }
                       onChange={ e => setName(e.currentTarget.value) }/>
              </div>
              <div className="contact-item">
                <label>Vaš email</label>
                <input type="email" name="cf-email" required={ true } value={ email }
                       onChange={ e => setEmail(e.currentTarget.value) }/>
              </div>
              <div className="contact-item">
                <label>Vaša poruka</label>
                <textarea rows={ 4 } name="cf-message" value={ message }
                          onChange={ e => setMessage(e.currentTarget.value) }/>
              </div>
              <div className="contact-item">
                <button
                  type="submit"
                  className="btn btn-style-3"
                  datatype="submit"
                >
                  Pošalji
                </button>
              </div>

              { responseMessage && <div className="contact-item">
                <p>{ responseMessage }</p>
              </div> }
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
