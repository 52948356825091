function ClassSchedule() {
  return (
    <>
      {/* - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - */ }
      <div id="content" className="page-content-wrap">
        <div className="container wide desktop-schedule">
          <div className="content-element6">
            {/*<div className="row">*/}
            {/*  <div className="col-lg-2 col-md-4">*/}
            {/*    <div className="mad-custom-select">*/}
            {/*      <select data-default-text="Vrste yoge">*/}
            {/*        <option value="Option 1">Hatha</option>*/}
            {/*        <option value="Option 2">Vinyasa</option>*/}
            {/*        <option value="Option 3">Prenatal</option>*/}
            {/*      </select>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className="responsive-table">
            <table className="tribe-events-calendar">
              <thead>
              <tr>
                <th/>
                <th>Ponedeljak</th>
                <th>Utorak</th>
                <th>Sreda</th>
                <th>Četvrtak</th>
                <th>Petak</th>
                <th>Subota</th>
                <th>Nedelja</th>
              </tr>
              </thead>
              <tbody>
              {/* Slot from 7-815*/}
              <tr>
                <td className="time" data-title="Work Time">
                  7:00 - 8:15
                </td>
                <td data-title="Monday"></td>
                <td data-title="Tuesday">
                  <div className="tribe-events-category-tech-events">
                    <h6>
                      <a href="#" className="tribe-title">
                        Rise and Shine (Vinyasa)
                      </a>
                    </h6>
                    Stefana Demić
                    <div className="tribe-events-tooltip">
                      <header>
                        <h6>Rise and Shine (Vinyasa)</h6>
                        <span>Stefana Demić</span>
                      </header>
                      <div className="clearfix">
                        <img
                          src="/images/190x130_img1.jpg"
                          alt=""
                          className="alignleft"
                        />
                        <p>
                          Sed in lacus ut enim adipiscing aliquet. Nulla
                          venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus
                          eget, elementum vel, cursus eleifend...
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td/>
                <td data-title="Thursday">
                  <div className="tribe-events-category-tech-events">
                    <h6>
                      <a href="#" className="tribe-title">
                        Rise and Shine (Vinyasa)
                      </a>
                    </h6>
                    Stefana Demić
                    <div className="tribe-events-tooltip">
                      <header>
                        <h6>Rise and Shine (Vinyasa)</h6>
                        <span>Stefana Demić</span>
                      </header>
                      <div className="clearfix">
                        <img
                          src="/images/190x130_img1.jpg"
                          alt=""
                          className="alignleft"
                        />
                        <p>
                          Sed in lacus ut enim adipiscing aliquet. Nulla
                          venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus
                          eget, elementum vel, cursus eleifend...
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td data-title="Friday">
                </td>
                <td data-title="Saturday">
                </td>
                <td/>
              </tr>
              {/* Slot from 10-1115 */}
              <tr>
                <td className="time" data-title="Work Time">
                  10:00 - 11:15
                </td>
                <td data-title="Monday"></td>
                <td data-title="Tuesday">
                  <div className="tribe-events-category-tech-events">
                    <h6>
                      <a href="#" className="tribe-title">
                        Hatha great day (Hatha)
                      </a>
                    </h6>
                    Stefana Demić
                    <div className="tribe-events-tooltip">
                      <header>
                        <h6>Hatha great day (Hatha)</h6>
                        <span>Stefana Demić</span>
                      </header>
                      <div className="clearfix">
                        <img
                          src="/images/190x130_img1.jpg"
                          alt=""
                          className="alignleft"
                        />
                        <p>
                          Sed in lacus ut enim adipiscing aliquet. Nulla
                          venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus
                          eget, elementum vel, cursus eleifend...
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td/>
                <td data-title="Thursday">
                  <div className="tribe-events-category-tech-events">
                    <div className="tribe-events-category-tech-events">
                      <h6>
                        <a href="#" className="tribe-title">
                          Hatha great day (Hatha)
                        </a>
                      </h6>
                      Stefana Demić
                      <div className="tribe-events-tooltip">
                        <header>
                          <h6>Hatha great day (Hatha)</h6>
                          <span>Stefana Demić</span>
                        </header>
                        <div className="clearfix">
                          <img
                            src="/images/190x130_img1.jpg"
                            alt=""
                            className="alignleft"
                          />
                          <p>
                            Sed in lacus ut enim adipiscing aliquet. Nulla
                            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus
                            eget, elementum vel, cursus eleifend...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td data-title="Friday">
                </td>
                <td data-title="Saturday">
                </td>
                <td/>
              </tr>
              {/* Slot from 18-1815 */}
              <tr>
                <td className="time" data-title="Work Time">
                  18:00 - 19:15
                </td>
                <td data-title="Monday">
                  <div className="tribe-events-category-tech-events">
                    <h6>
                      <a href="#" className="tribe-title">
                        Happy hour (Hatha)
                      </a>
                    </h6>
                    Stefana Demić
                    <div className="tribe-events-tooltip">
                      <header>
                        <h6>Happy hour (Hatha)</h6>
                        <span>Stefana Demić</span>
                      </header>
                      <div className="clearfix">
                        <img
                          src="/images/190x130_img1.jpg"
                          alt=""
                          className="alignleft"
                        />
                        <p>
                          Sed in lacus ut enim adipiscing aliquet. Nulla
                          venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus
                          eget, elementum vel, cursus eleifend...
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td/>
                <td data-title="Wednesday">
                  <div className="tribe-events-category-tech-events">
                    <h6>
                      <a href="#" className="tribe-title">
                        Happy hour (Hatha)
                      </a>
                    </h6>
                    Stefana Demić
                    <div className="tribe-events-tooltip">
                      <header>
                        <h6>Happy hour (Hatha)</h6>
                        <span>Stefana Demić</span>
                      </header>
                      <div className="clearfix">
                        <img
                          src="/images/190x130_img1.jpg"
                          alt=""
                          className="alignleft"
                        />
                        <p>
                          Sed in lacus ut enim adipiscing aliquet. Nulla
                          venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus
                          eget, elementum vel, cursus eleifend...
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td data-title="Thursday"/>
                <td data-type="Friday">
                  <div className="tribe-events-category-tech-events">
                    <h6>
                      <a href="#" className="tribe-title">
                        Happy hour (Hatha)
                      </a>
                    </h6>
                    Stefana Demić
                    <div className="tribe-events-tooltip">
                      <header>
                        <h6>Happy hour (Hatha)</h6>
                        <span>Stefana Demić</span>
                      </header>
                      <div className="clearfix">
                        <img
                          src="/images/190x130_img1.jpg"
                          alt=""
                          className="alignleft"
                        />
                        <p>
                          Sed in lacus ut enim adipiscing aliquet. Nulla
                          venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus
                          eget, elementum vel, cursus eleifend...
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td data-type="Saturday"/>
                <td data-title="Sunday"/>
              </tr>
              <tr>
                <td className="time" data-title="Work Time">
                  20:00 - 21:15
                </td>
                <td data-title="Monday"></td>
                <td data-title="Tuesday">
                  <div className="tribe-events-category-tech-events">
                    <h6>
                      <a href="#" className="tribe-title">
                        After Party (Vinyasa)
                      </a>
                    </h6>
                    Stefana Demić
                    <div className="tribe-events-tooltip">
                      <header>
                        <h6>After Party (Vinyasa)</h6>
                        <span>Stefana Demić</span>
                      </header>
                      <div className="clearfix">
                        <img
                          src="/images/190x130_img1.jpg"
                          alt=""
                          className="alignleft"
                        />
                        <p>
                          Sed in lacus ut enim adipiscing aliquet. Nulla
                          venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus
                          eget, elementum vel, cursus eleifend...
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td/>
                <td data-title="Thursday">
                  <div className="tribe-events-category-tech-events">
                    <h6>
                      <a href="#" className="tribe-title">
                        After Party (Vinyasa)
                      </a>
                    </h6>
                    Stefana Demić
                    <div className="tribe-events-tooltip">
                      <header>
                        <h6>After Party (Vinyasa)</h6>
                        <span>Stefana Demić</span>
                      </header>
                      <div className="clearfix">
                        <img
                          src="/images/190x130_img1.jpg"
                          alt=""
                          className="alignleft"
                        />
                        <p>
                          Sed in lacus ut enim adipiscing aliquet. Nulla
                          venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus
                          eget, elementum vel, cursus eleifend...
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td data-title="Friday">
                </td>
                <td data-title="Saturday">
                </td>
                <td/>
              </tr>


              {/*<tr>*/}
              {/*  <td className="time" data-title="Work Time">*/}
              {/*    12:30 PM - 1:30 PM*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*  <td data-title="Monday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Hatha*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Vicky Johnson*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Hatha</h6>*/}
              {/*          <span>Vicky Johnson</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*  <td data-title="Wednesday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Pilates*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Stella Healy*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Pilates</h6>*/}
              {/*          <span>Stella Healy</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*  <td data-title="Friday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Restorative Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Stella Healy*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Restorative Yoga</h6>*/}
              {/*          <span>Stella Healy</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td className="time" data-title="Work Time">*/}
              {/*    1:00 PM - 2:00 PM*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*  <td/>*/}
              {/*  <td data-title="Tuesday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Yin Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Alan Smith*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Yin Yoga</h6>*/}
              {/*          <span>Alan Smith</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Wednesday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Gentle Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Bradley Grosh*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Gentle Yoga</h6>*/}
              {/*          <span>Bradley Grosh</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Thursday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Pilates*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Stella Healy*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Pilates</h6>*/}
              {/*          <span>Stella Healy</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*  <td data-title="Saturday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Hatha*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Vicky Johnson*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Hatha</h6>*/}
              {/*          <span>Vicky Johnson</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td className="time" data-title="Work Time">*/}
              {/*    4:00 PM - 5:00 PM*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*  <td data-title="Monday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Hatha*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Vicky Johnson*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Hatha</h6>*/}
              {/*          <span>Vicky Johnson</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Tuesday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Yin Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Alan Smith*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Yin Yoga</h6>*/}
              {/*          <span>Alan Smith</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*  <td data-title="Thursday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Pilates*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Stella Healy*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Pilates</h6>*/}
              {/*          <span>Stella Healy</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Friday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Restorative Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Stella Healy*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Restorative Yoga</h6>*/}
              {/*          <span>Stella Healy</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td className="time" data-title="Work Time">*/}
              {/*    5:30 PM - 6:30 PM*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*  <td data-title="Monday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Gentle Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Bradley Grosh*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Gentle Yoga</h6>*/}
              {/*          <span>Bradley Grosh</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*  <td data-title="Wednesday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Gentle Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Bradley Grosh*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Gentle Yoga</h6>*/}
              {/*          <span>Bradley Grosh</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Thursday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Yin Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Alan Smith*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Yin Yoga</h6>*/}
              {/*          <span>Alan Smith</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Friday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Pilates*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Stella Healy*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Pilates</h6>*/}
              {/*          <span>Stella Healy</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Saturday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Hatha*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Vicky Johnson*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Hatha</h6>*/}
              {/*          <span>Vicky Johnson</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td className="time" data-title="Work Time">*/}
              {/*    7:00 PM - 8:00 PM*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*  <td data-title="Monday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Hatha*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Vicky Johnson*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Hatha</h6>*/}
              {/*          <span>Vicky Johnson</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Tuesday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Restorative Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Stella Healy*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Restorative Yoga</h6>*/}
              {/*          <span>Stella Healy</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Wednesday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Gentle Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Bradley Grosh*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Gentle Yoga</h6>*/}
              {/*          <span>Bradley Grosh</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*  <td data-title="Friday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Hatha*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Vicky Johnson*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Hatha</h6>*/}
              {/*          <span>Vicky Johnson</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Saturday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Gentle Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Bradley Grosh*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Gentle Yoga</h6>*/}
              {/*          <span>Bradley Grosh</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*</tr>*/}
              {/*<tr>*/}
              {/*  <td className="time" data-title="Work Time">*/}
              {/*    8:30 PM - 9:30 PM*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*  <td/>*/}
              {/*  <td data-title="Tuesday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Yin Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Alan Smith*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Yin Yoga</h6>*/}
              {/*          <span>Alan Smith</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Wednesday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Hatha*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Vicky Johnson*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Hatha</h6>*/}
              {/*          <span>Vicky Johnson</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Thursday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Gentle Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Bradley Grosh*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Gentle Yoga</h6>*/}
              {/*          <span>Bradley Grosh</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td data-title="Friday">*/}
              {/*    <div className="tribe-events-category-tech-events">*/}
              {/*      <h6>*/}
              {/*        <a href="#" className="tribe-title">*/}
              {/*          Yin Yoga*/}
              {/*        </a>*/}
              {/*      </h6>*/}
              {/*      Alan Smith*/}
              {/*      <div className="tribe-events-tooltip">*/}
              {/*        <header>*/}
              {/*          <h6>Yin Yoga</h6>*/}
              {/*          <span>Alan Smith</span>*/}
              {/*        </header>*/}
              {/*        <div className="clearfix">*/}
              {/*          <img*/}
              {/*            src="/images/190x130_img1.jpg"*/}
              {/*            alt=""*/}
              {/*            className="alignleft"*/}
              {/*          />*/}
              {/*          <p>*/}
              {/*            Sed in lacus ut enim adipiscing aliquet. Nulla*/}
              {/*            venenatis. In pede mi, aliquet. Ut tellus dolor, dapibus*/}
              {/*            eget, elementum vel, cursus eleifend, [...]*/}
              {/*          </p>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td/>*/}
              {/*</tr>*/}
              </tbody>
            </table>
          </div>
        </div>
        <div className={'mobile-schedule'}>
        {/* A List weekdays and class schedule for each day */}
          <ul>
            <li>
              <h5>Ponedeljak</h5>
              <ul>
                <li>
                  <li>-------------</li>
                  <h6>18:00 - 19:15</h6>
                  <h6>Happy hour (Hatha)</h6>
                  <h6>Stefana Demić</h6>
                </li>
              </ul>
            </li>

            <li>
              <h5>Utorak</h5>
              <ul>
                <li>
                  <li>-------------</li>
                  <h6>7:00 - 8:15</h6>
                  <h6>Rise and Shine (Vinyasa)</h6>
                  <h6>Stefana Demić</h6>
                </li>
                <li>
                  <li>-------------</li>
                  <h6>10:00 - 11:15</h6>
                  <h6>Hatha great day (Hatha)</h6>
                  <h6>Stefana Demić</h6>
                </li>
                <li>
                  <li>-------------</li>
                  <h6>20:00 - 21:15</h6>
                  <h6>After Party (Vinyasa)</h6>
                  <h6>Stefana Demić</h6>
                </li>
              </ul>
            </li>

            <li>
              <h5>Sreda</h5>
              <ul>
                <li>
                  <li>-------------</li>
                  <h6>18:00 - 19:15</h6>
                  <h6>Happy hour (Hatha)</h6>
                  <h6>Stefana Demić</h6>
                </li>
              </ul>
            </li>

            <li>
              <h5>Četvrtak</h5>
              <ul>
                <li>
                  <li>-------------</li>
                  <h6>7:00 - 8:15</h6>
                  <h6>Rise and Shine (Vinyasa)</h6>
                  <h6>Stefana Demić</h6>
                </li>
                <li>
                  <li>-------------</li>
                  <h6>10:00 - 11:15</h6>
                  <h6>Hatha great day (Hatha)</h6>
                  <h6>Stefana Demić</h6>
                </li>
                <li>
                  <li>-------------</li>
                  <h6>20:00 - 21:15</h6>
                  <h6>After Party (Vinyasa)</h6>
                  <h6>Stefana Demić</h6>
                </li>
              </ul>
            </li>

            <li>
              <h5>Petak</h5>
              <ul>
                <li>
                  <li>-------------</li>
                  <h6>18:00 - 19:15</h6>
                  <h6>Happy hour (Hatha)</h6>
                  <h6>Stefana Demić</h6>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default ClassSchedule;
