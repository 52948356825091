import ClassSchedule from '../../components/ClassSchedule/ClassSchedule';
import GeoMap from '../../components/GeoMap/GeoMap';
import { useState } from 'react';

function Landing() {

  const [responseMessage, setResponseMessage] = useState('');

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const triggerOnFormSubmit = async (e: { preventDefault: () => void; }) => {
    console.log(e);
    e.preventDefault();
    console.log('triggerOnFormSubmit');

    // Call backend with our three variables
    const baseUrl = process.env.REACT_APP_API_ENDPOINT;

    const res = await fetch(`${ baseUrl }/contact-form`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        name,
        message,
      }),
    });

    console.log(res);

    if (res.status >= 200 && res.status < 300) {
      setResponseMessage('Uspesno ste poslali poruku.');
      // set all fields to empty
      setEmail('');
      setName('');
      setMessage('');
    } else {
      setResponseMessage('Doslo je do greske. Pokusajte ponovo.');
    }
  };

  return (
    <div>
      {/* - - - - - - - - - - - - - Revolution Slider - - - - - - - - - - - - - - - - */ }
      <div className="rev-slider-wrapper">
        <div
          id="rev-slider"
          className="rev-slider tp-simpleresponsive"
          data-version={ 5.0 }
        >
          <ul>
            <li data-transition="fade">
              <img src="/images/1920x858_bg1.jpg" className="rev-slidebg" alt=""/>
              {/* - - - - - - - - - - - - - Layer 1 - - - - - - - - - - - - - - - - */ }
              <div
                className="tp-caption tp-resizeme"
                data-x="['left','left','left','left']"
                data-hoffset={ 30 }
                data-y="['middle','middle','middle','middle']"
                data-voffset={ -110 }
                data-whitespace="nowrap"
                data-frames='[{"delay":150,"speed":2000,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":1000,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                data-responsive_offset="on"
                data-elementdelay="0.05"
              >
                <div className="section-pre-title">dobrodošli u naš studio</div>
              </div>
              {/* - - - - - - - - - - - - - End of Layer 1 - - - - - - - - - - - - - - - - */ }
              {/* - - - - - - - - - - - - - Layer 2 - - - - - - - - - - - - - - - - */ }
              <div
                className="tp-caption tp-resizeme scaption-dark-large"
                data-x="['left','left','left','left']"
                data-hoffset={ 30 }
                data-y="['middle','middle','middle','middle']"
                data-voffset={ 0 }
                data-whitespace="nowrap"
                data-frames='[{"delay":450,"speed":2000,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":1000,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                data-responsive_offset="on"
                data-elementdelay="0.05"
              >
                Umetnost <br/> pametnih pokreta
              </div>
              {/* - - - - - - - - - - - - - End of Layer 2 - - - - - - - - - - - - - - - - */ }

              {/* - - - - - - - - - - - - - Layer 3 - - - - - - - - - - - - - - - - */ }
              <div
                className="tp-caption tp-resizeme"
                data-x="['left','left','left','left']"
                data-hoffset={ 30 }
                data-y="['middle','middle','middle','middle']"
                data-voffset={ 130 }
                data-whitespace="nowrap"
                data-frames='[{"delay":750,"speed":2000,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":1000,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                data-responsive_offset="on"
                data-elementdelay="0.05"
              >
                <a href="/casovi/raspored" className="btn btn-big btn-style-3">
                  Pogledajte raspored
                </a>
                <a href="/studio/o-nama" className="btn btn-big">
                  Više o nama
                </a>
              </div>
              {/* - - - - - - - - - - - - - End of Layer 3 - - - - - - - - - - - - - - - - */ }
            </li>
            <li data-transition="fade">
              <img src="/images/1920x858_bg2.jpg" className="rev-slidebg" alt=""/>
              {/* - - - - - - - - - - - - - Layer 1 - - - - - - - - - - - - - - - - */ }
              <div
                className="tp-caption tp-resizeme"
                data-x="['right','right','right','right']"
                data-hoffset={ 410 }
                data-y="['middle','middle','middle','middle']"
                data-voffset={ -110 }
                data-whitespace="nowrap"
                data-frames='[{"delay":150,"speed":2000,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":1000,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                data-responsive_offset="on"
                data-elementdelay="0.05"
              >
                <div className="section-pre-title">Posebni paketi</div>
              </div>
              {/* - - - - - - - - - - - - - End of Layer 1 - - - - - - - - - - - - - - - - */ }

              {/* - - - - - - - - - - - - - Layer 2 - - - - - - - - - - - - - - - - */ }
              <div
                className="tp-caption tp-resizeme scaption-dark-large"
                data-x="['right','right','right','right']"
                data-hoffset={ 50 }
                data-y="['middle','middle','middle','middle']"
                data-voffset={ 0 }
                data-whitespace="nowrap"
                data-frames='[{"delay":150,"speed":2000,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":1000,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                data-responsive_offset="on"
                data-elementdelay="0.05"
              >
                2 nedelje besplatno <br/> uz tromesečnu pretplatu
              </div>
              {/* - - - - - - - - - - - - - End of Layer 2 - - - - - - - - - - - - - - - - */ }

              {/* - - - - - - - - - - - - - Layer 3 - - - - - - - - - - - - - - - - */ }
              <div
                className="tp-caption tp-resizeme"
                data-x="['right','right','right','right']"
                data-hoffset={ 440 }
                data-y="['middle','middle','middle','middle']"
                data-voffset={ 130 }
                data-whitespace="nowrap"
                data-frames='[{"delay":450,"speed":2000,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":1000,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                data-responsive_offset="on"
                data-elementdelay="0.05"
              >
                <a href="/studio/kontakt" className="btn btn-big btn-style-3">
                  Saznajte više
                </a>
              </div>
              {/* - - - - - - - - - - - - - End of Layer 3 - - - - - - - - - - - - - - - - */ }

            </li>
            <li data-transition="fade">
              <img src="/images/1920x858_bg3.jpg" className="rev-slidebg" alt=""/>
              {/* - - - - - - - - - - - - - Layer 1 - - - - - - - - - - - - - - - - */ }
              <div
                className="tp-caption tp-resizeme scaption-dark-large align-center"
                data-x="['center','center','center','center']"
                data-hoffset={ 0 }
                data-y="['middle','middle','middle','middle']"
                data-voffset={ -20 }
                data-whitespace="nowrap"
                data-frames='[{"delay":150,"speed":2000,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":1000,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                data-responsive_offset="on"
                data-elementdelay="0.05"
              >
                Raznovrsni tipovi joge, <br/> Izazovi i dogadjaji
              </div>
              {/* - - - - - - - - - - - - - End of Layer 1 - - - - - - - - - - - - - - - - */ }
              {/* - - - - - - - - - - - - - Layer 2 - - - - - - - - - - - - - - - - */ }
              <div
                className="tp-caption tp-resizeme"
                data-x="['center','center','center','center']"
                data-hoffset={ 0 }
                data-y="['middle','middle','middle','middle']"
                data-voffset={ 120 }
                data-whitespace="nowrap"
                data-frames='[{"delay":750,"speed":2000,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":1000,"frame":"999","to":"y:[175%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                data-responsive_offset="on"
                data-elementdelay="0.05"
              >
                <a href="/casovi/vrste-casova" className="btn btn-big btn-style-3">
                  Više detalja
                </a>
                <a href="/casovi/raspored" className="btn btn-big">
                  Pogledajte raspored
                </a>
              </div>
              {/* - - - - - - - - - - - - - End of Layer 2 - - - - - - - - - - - - - - - - */ }
            </li>
          </ul>
        </div>
      </div>
      {/* - - - - - - - - - - - - - End of Slider - - - - - - - - - - - - - - - - */ }


      {/* - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - */ }
      <div id="content">
        {/* Info box */ }
        <div className="info-boxes style-2 flex-row no-gutters item-col-4">
          <div className="info-box" data-bg="/images/480x338_bg1.jpg">
            <h3 className="box-title">
              <a href="/studio/faq">Početnik?</a>
            </h3>
            <p>
              Ako tek sada ulazite u svet yoge i imate dosta pitanja i nedoumica ovo je pravo mesto za vas.
              <br/> Pogledajte naš FAQ ili nas kontaktirajte.
            </p>
            <a href="/studio/faq" className="btn btn-style-2">
              Pročitajte više
            </a>
          </div>
          <div className="info-box" data-bg="/images/480x338_bg2.jpg">
            <h3 className="box-title">
              <a href="/studio/o-nama">Unutar studija</a>
            </h3>
            <p>
              Pogledajte kako izgledaju časovi, izazovi i dogadjaji koji se odvijaju u nasem studiju.
            </p>
            <a href="/studio/o-nama" className="btn btn-style-2">
              Pogledaj
            </a>
          </div>
          <div className="info-box" data-bg="/images/480x338_bg3.jpg">
            <h3 className="box-title">
              <a href="/casovi/vrste-casova">Časovi</a>
            </h3>
            <p>
              U našem studiju časovi se odvijaju u svim delovima dana kao i iz različitih tipova joge.
              <br/> Pronadjite savršen čas za sebe.
            </p>
            <a href="/casovi/raspored" className="btn btn-style-2">
              Raspored
            </a>
          </div>
          <div className="info-box" data-bg="/images/480x338_bg4.jpg">
            <h3 className="box-title">
              <a href="/studio/kontakt">Dogadjaji</a>
            </h3>
            <p>
              Konstantno održavamo zanimljive dogadjaje i izazove u našem studiju kao i po Srbiji i regionu.
            </p>
            <a href="/studio/kontakt" className="btn btn-style-2">
              Dogadjaji
            </a>
          </div>
        </div>

        {/* Page section */ }
        <div className="page-section">
          <ClassSchedule/>
        </div>

        {/* Page section */ }
        <div
          className="page-section parallax-section text-color-light"
          data-bg="/images/1920x1000_bg1.jpg"
        >
          <div className="container wide2">
            <div className="content-element4">
              <div className="section-pre-title">Šta naši vežbači kažu o nama</div>
              <h2 className="section-title">Utisci</h2>
            </div>
            <div className="carousel-type-2">
              <div
                className="owl-carousel testimonial-holder"
                data-max-items={ 3 }
                data-autoplay="true"
              >
                {/* - - - - - - - - - - - - - Testimonial - - - - - - - - - - - - - - - - */ }
                <div className="testimonial bg1">
                  <blockquote>
                    <p>
                      “Fenomenalna atmosfera, pravi tempo i posvećenost svakom vežbacču.”{ ' ' }
                    </p>
                    <div className="author-box">
                  <span className="author-icon">
                    <i className="svg"/>
                  </span>
                      <div className="author-info">
                        <div className="author">Milica G.</div>
                        {/*<a href="#" className="author-position">*/}
                        {/*  Beograd, Srbija*/}
                        {/*</a>*/}
                      </div>
                    </div>
                  </blockquote>
                </div>
                {/* /Carousel Item */ }
                {/* - - - - - - - - - - - - - Testimonial - - - - - - - - - - - - - - - - */ }
                <div className="testimonial bg2">
                  <blockquote>
                    <p>
                      “Opustajucći treninzi, uvek se osećam bolje posle njih. Nidra mi je omiljena.”{ ' ' }
                    </p>
                    <div className="author-box">
                  <span className="author-icon">
                    <i className="svg"/>
                  </span>
                      <div className="author-info">
                        <div className="author">Jovana D.</div>
                        {/*<a href="#" className="author-position">*/}
                        {/*  Beograd, Srbija.*/}
                        {/*</a>*/}
                      </div>
                    </div>
                  </blockquote>
                </div>
                {/* /Carousel Item */ }
                {/* - - - - - - - - - - - - - Testimonial - - - - - - - - - - - - - - - - */ }
                <div className="testimonial bg3">
                  <blockquote>
                    <p>
                      “Ledja su mi pravila problem godinama, posle par meseci vežbanja kod Stefana
                      vise nemam nikakvih tegoba. Magično!”
                    </p>
                    <div className="author-box">
                  <span className="author-icon">
                    <i className="svg"/>
                  </span>
                      <div className="author-info">
                        <div className="author">Nemanja I.</div>
                        {/*<a href="#" className="author-position">*/}
                        {/*  San Dirgo, CA*/}
                        {/*</a>*/}
                      </div>
                    </div>
                  </blockquote>
                </div>
                {/* /Carousel Item */ }
              </div>
              {/*<a href="#" className="btn btn-style-2">*/}
              {/*  Još utisaka*/}
              {/*</a>*/}
            </div>
          </div>
        </div>
        {/* Page section */ }
        <div className="page-section-bg">
          <div className="container wide2">
            <div className="content-element4">
              <div className="section-pre-title">Instruktori</div>
              <h2 className="section-title">Upoznajte naš talentovani tim</h2>
            </div>
            <div className="carousel-type-2">
              <div
                className="team-holder owl-carousel"
                data-item-margin={ 30 }
                data-max-items={ 3 }
              >
                {/* team element */ }
                <div className="team-item">
                  <a href="#" className="member-photo">
                    <img src="/images/289x312_photo1.jpg" alt=""/>
                  </a>
                  <div className="team-desc">
                    <div className="member-info">
                      <h5 className="member-name">
                        <a href="#">Stefana Demić</a>
                      </h5>
                      <h6 className="member-position">
                        Hatha, Vinyasa, Prenatal Yoga instruktor
                      </h6>
                    </div>
                  </div>
                </div>
                {/* team element */ }
                <div className="team-item">
                  <a href="#" className="member-photo">
                    <img src="/images/289x312_photo2.jpg" alt=""/>
                  </a>
                  <div className="team-desc">
                    <div className="member-info">
                      <h5 className="member-name">
                        <a href="#">Nikola Petrović</a>
                      </h5>
                      <h6 className="member-position">Ashtanga Yoga Instruktor</h6>
                    </div>
                  </div>
                </div>
                {/* team element */ }
                <div className="team-item">
                  <a href="#" className="member-photo">
                    <img src="/images/289x312_photo3.jpg" alt=""/>
                  </a>
                  <div className="team-desc">
                    <div className="member-info">
                      <h5 className="member-name">
                        <a href="#">Milica Gut</a>
                      </h5>
                      <h6 className="member-position">
                        Hatha Yoga Instruktor
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="call-out bg-section" data-bg="/images/1920x286_bg1.jpg">
          <div className="container wide2">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-12">
                <div className="align-center">
                  <h2 className="call-title var2">Članarine i paketi</h2>
                  <p>Rezervišite ili kupite online</p>
                  <a href="/casovi/cenovnik" className="btn btn-style-6 btn-big">
                    Pogledaj celu ponudu
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page section */ }
        <div className="page-section-bg half-bg-col padding-x-160">
          <div className="img-col-left">
            <div className="col-bg" data-bg="/images/445x468_phone.jpg"/>
          </div>
          <div className="container">
            <div className="row align-content-center">
              <div className="col-md-6"/>
              <div className="col-md-6 online-classes-section">
                <h2>Online časovi i kursevi</h2>
                <p className="content-element4">
                  U našem studiju mozete da pratite časove ne samo uživo već i online kao i da se prijavite za neke od nasih online kurseva.
                  Kurseve mozete da pratite svojim tempom i u bilo koje vreme.
                </p>
                <div className="store-btns">
                  <a href="/casovi/cenovnik" className="btn btn-style-6 btn-big">
                    Online kursevi
                  </a>
                  <a href="/casovi/cenovnik" className="btn btn-style-5 btn-big">
                    Online časovi
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Page section */ }
        <div className="page-section-bg half-bg-col text-color-light">
          <div className="img-col-left">
            <div className="col-bg" data-bg="/images/960x884_bg2.jpg"/>
          </div>
          <div className="img-col-right">
            <div className="col-bg" data-bg="/images/960x884_bg1.jpg"/>
          </div>
          <div className="container wide2">
            <div className="row align-content-center">
              <div className="col-lg-5">
                <div className="content-element4">
                  <div className="section-pre-title">Dogadjaji i work</div>
                  <h2 className="section-title">Predstojeci dogadjaji</h2>
                </div>
                <div className="carousel-type-2">
                  <div
                    className="owl-carousel flex-row entry-box style-2"
                    data-max-items={ 2 }
                    data-item-margin={ 30 }
                  >
                    {/* - - - - - - - - - - - - - Entry - - - - - - - - - - - - - - - - */ }
                    <div className="entry">
                      {/* - - - - - - - - - - - - - Entry attachment - - - - - - - - - - - - - - - - */ }
                      <div className="thumbnail-attachment">
                        <a href="#">
                          <img src="/images/237x156_img1.jpg" alt=""/>
                        </a>
                      </div>
                      {/* - - - - - - - - - - - - - Entry body - - - - - - - - - - - - - - - - */ }
                      <div className="entry-body">
                        <h6 className="entry-title">
                          <a href="#">
                            Pozdrav suncu - Jutarnja joga na Adi Ciganliji
                          </a>
                        </h6>
                        <div className="our-info vr-type">
                          <div className="info-item">
                            <i className="licon-clock3"/>
                            <div className="wrapper">
                              <span>5 Oktobar | 9:00 - 10:30, 2022</span>
                            </div>
                          </div>
                          <div className="info-item">
                            <i className="licon-map-marker"/>
                            <div className="wrapper">
                              <span>Ada Ciganlija, Beograd</span>
                            </div>
                          </div>
                        </div>
                        <div className="entry-price">
                          <span className="product-price">Besplatno</span>
                          <a href="#" className="btn btn-small">
                            Registruj se
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* - - - - - - - - - - - - - Entry - - - - - - - - - - - - - - - - */ }
                    <div className="entry">
                      {/* - - - - - - - - - - - - - Entry attachment - - - - - - - - - - - - - - - - */ }
                      <div className="thumbnail-attachment">
                        <a href="#">
                          <img src="/images/237x156_img2.jpg" alt=""/>
                        </a>
                      </div>
                      {/* - - - - - - - - - - - - - Entry body - - - - - - - - - - - - - - - - */ }
                      <div className="entry-body">
                        <h6 className="entry-title">
                          <a href="#">Yoga Nidra, umetnost disanja</a>
                        </h6>
                        <div className="our-info vr-type">
                          <div className="info-item">
                            <i className="licon-clock3"/>
                            <div className="wrapper">
                              <span>18 Oktobar, 2022 | 20:00 - 21:00</span>
                            </div>
                          </div>
                          <div className="info-item">
                            <i className="licon-map-marker"/>
                            <div className="wrapper">
                              <span>Internacionalnih brigada 25A, Beograd, Vračar</span>
                            </div>
                          </div>
                        </div>
                        <div className="entry-price">
                          <span className="product-price free">1000RSD</span>
                          <a href="#" className="btn btn-small">
                            Registruj se
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a href="/dogadjaji" className="btn btn-style-2">
                    Jos dogadjaja
                  </a>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1"/>
            </div>
          </div>
        </div>
        {/* Page section */ }
        {/*<div className="page-section-bg">*/}
        {/*  <div className="container wide2">*/}
        {/*    <div className="content-element4">*/}
        {/*      <div className="section-pre-title">Yoga Proizvodi</div>*/}
        {/*      <h2 className="section-title">U našoj prodavnici</h2>*/}
        {/*    </div>*/}
        {/*    <div className="carousel-type-2">*/}
        {/*      <div*/}
        {/*        className="owl-carousel products-holder"*/}
        {/*        data-item-margin={ 30 }*/}
        {/*        data-max-items={ 5 }*/}
        {/*      >*/}
        {/*        /!* Product */ }
        {/*        <div className="product">*/}
        {/*          /!* - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - */ }
        {/*          <figure className="product-image">*/}
        {/*            <a href="#">*/}
        {/*              <img src="/images/289x286_img1.jpg" alt=""/>*/}
        {/*            </a>*/}
        {/*          </figure>*/}
        {/*          /!* - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - */ }
        {/*          /!* - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - */ }
        {/*          <div className="product-description">*/}
        {/*            <a href="#" className="product-cat">*/}
        {/*              Oprema*/}
        {/*            </a>*/}
        {/*            <h6 className="product-name">*/}
        {/*              <a href="#">Non Slip Yoga Mat</a>*/}
        {/*            </h6>*/}
        {/*            <div className="pricing-area">*/}
        {/*              <div className="product-price">39.95EUR</div>*/}
        {/*              <ul className="rating">*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li className="empty">*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*              </ul>*/}
        {/*            </div>*/}
        {/*            <a href="#" className="btn btn-small btn-style-3">*/}
        {/*              Dodaj u korpu*/}
        {/*            </a>*/}
        {/*          </div>*/}
        {/*          /!* - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - */ }
        {/*        </div>*/}
        {/*        /!* Product */ }
        {/*        <div className="product">*/}
        {/*          /!* - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - */ }
        {/*          <figure className="product-image">*/}
        {/*            <a href="#">*/}
        {/*              <img src="/images/289x286_img2.jpg" alt=""/>*/}
        {/*            </a>*/}
        {/*          </figure>*/}
        {/*          /!* - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - */ }
        {/*          /!* - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - */ }
        {/*          <div className="product-description">*/}
        {/*            <a href="#" className="product-cat">*/}
        {/*              Garderoba*/}
        {/*            </a>*/}
        {/*            <h6 className="product-name">*/}
        {/*              <a href="#">Yoga Majca - Namaste Love </a>*/}
        {/*            </h6>*/}
        {/*            <div className="pricing-area">*/}
        {/*              <div className="product-price">*/}
        {/*                <span>49.95EUR</span> 38.95EUR*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*            <a href="#" className="btn btn-small btn-style-3">*/}
        {/*              Dodaj u korpu*/}
        {/*            </a>*/}
        {/*          </div>*/}
        {/*          /!* - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - */ }
        {/*        </div>*/}
        {/*        /!* Product */ }
        {/*        <div className="product">*/}
        {/*          /!* - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - */ }
        {/*          <figure className="product-image">*/}
        {/*            <a href="#">*/}
        {/*              <img src="/images/289x286_img3.jpg" alt=""/>*/}
        {/*            </a>*/}
        {/*          </figure>*/}
        {/*          /!* - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - */ }
        {/*          /!* - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - */ }
        {/*          <div className="product-description">*/}
        {/*            <a href="#" className="product-cat">*/}
        {/*              Oprema*/}
        {/*            </a>*/}
        {/*            <h6 className="product-name">*/}
        {/*              <a href="#">Light Hard Foam Yoga Blocks</a>*/}
        {/*            </h6>*/}
        {/*            <div className="pricing-area">*/}
        {/*              <div className="product-price">11.35EUR</div>*/}
        {/*              <ul className="rating">*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*              </ul>*/}
        {/*            </div>*/}
        {/*            <a href="#" className="btn btn-small btn-style-3">*/}
        {/*              Dodaj u korpu*/}
        {/*            </a>*/}
        {/*          </div>*/}
        {/*          /!* - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - */ }
        {/*        </div>*/}
        {/*        /!* Product */ }
        {/*        <div className="product">*/}
        {/*          /!* - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - */ }
        {/*          <figure className="product-image">*/}
        {/*            <a href="#">*/}
        {/*              <img src="/images/289x286_img4.jpg" alt=""/>*/}
        {/*            </a>*/}
        {/*          </figure>*/}
        {/*          /!* - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - */ }
        {/*          /!* - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - */ }
        {/*          <div className="product-description">*/}
        {/*            <a href="#" className="product-cat">*/}
        {/*              Oprema*/}
        {/*            </a>*/}
        {/*            <h6 className="product-name">*/}
        {/*              <a href="#">Yoga kajis</a>*/}
        {/*            </h6>*/}
        {/*            <div className="pricing-area">*/}
        {/*              <div className="product-price">13.95EUR</div>*/}
        {/*              <ul className="rating">*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*              </ul>*/}
        {/*            </div>*/}
        {/*            <a href="#" className="btn btn-small btn-style-3">*/}
        {/*              Dodaj u korpu*/}
        {/*            </a>*/}
        {/*          </div>*/}
        {/*          /!* - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - */ }
        {/*        </div>*/}
        {/*        /!* Product */ }
        {/*        <div className="product">*/}
        {/*          /!* - - - - - - - - - - - - - Product Image - - - - - - - - - - - - - - - - */ }
        {/*          <figure className="product-image">*/}
        {/*            <a href="#">*/}
        {/*              <img src="/images/289x286_img5.jpg" alt=""/>*/}
        {/*            </a>*/}
        {/*          </figure>*/}
        {/*          /!* - - - - - - - - - - - - - End of Product Image - - - - - - - - - - - - - - - - */ }
        {/*          /!* - - - - - - - - - - - - - Product Description - - - - - - - - - - - - - - - - */ }
        {/*          <div className="product-description">*/}
        {/*            <a href="#" className="product-cat">*/}
        {/*              Lepota*/}
        {/*            </a>*/}
        {/*            <h6 className="product-name">*/}
        {/*              <a href="#">Yoga Blend Body Lotion</a>*/}
        {/*            </h6>*/}
        {/*            <div className="pricing-area">*/}
        {/*              <div className="product-price">14.99EUR</div>*/}
        {/*              <ul className="rating">*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*                <li>*/}
        {/*                  <i className="licon-star"/>*/}
        {/*                </li>*/}
        {/*              </ul>*/}
        {/*            </div>*/}
        {/*            <a href="#" className="btn btn-small btn-style-3">*/}
        {/*              Dodaj u korpu*/}
        {/*            </a>*/}
        {/*          </div>*/}
        {/*          /!* - - - - - - - - - - - - - End of Product Description - - - - - - - - - - - - - - - - */ }
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/* Page section */ }
        <div
          className="page-section parallax-section text-color-light"
          data-bg="/images/1920x1000_bg2.jpg"
        >
          <div className="container wide2">
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="content-element5">
                  <div className="section-pre-title">Imate pitanja?</div>
                  <h2 className="section-title">Kontaktirajte nas</h2>
                </div>
                <p className="content-element3">
                  Slobodno nam posaljite bilo koja pitanja vezana za yogu ili nas studio. Rado cemo vam odgovoriti.
                </p>
                <form id="contact-form" className="contact-form" onSubmit={ triggerOnFormSubmit }>
                  <div className="contact-item">
                    <label>Vaše ime</label>
                    <input type="text" name="cf-name" required={ true } value={ name }
                           onChange={ e => setName(e.currentTarget.value) }/>
                  </div>
                  <div className="contact-item">
                    <label>Vaš email</label>
                    <input type="email" name="cf-email" required={ true } value={ email }
                           onChange={ e => setEmail(e.currentTarget.value) }/>
                  </div>
                  <div className="contact-item">
                    <label>Vaša poruka</label>
                    <textarea rows={ 4 } name="cf-message" value={ message }
                              onChange={ e => setMessage(e.currentTarget.value) }/>
                  </div>
                  <div className="contact-item">
                    <button
                      type="submit"
                      className="btn btn-style-3"
                      datatype="submit"
                    >
                      Pošalji
                    </button>
                  </div>

                  { responseMessage && <div className="contact-item">
                    <p>{ responseMessage }</p>
                  </div> }
                </form>
              </div>
              <div className="col-lg-4 col-md-12 offset-lg-1">
                <div className="our-info style-2 vr-type var2">
                  <div className="info-item">
                    <i className="licon-map-marker"/>
                    <div className="wrapper">
                      <span className="info-cat">Adresa</span>
                    </div>
                    <h5 className="info-title">
                      Internacionalnih brigada 25A, <br/> Beograd, Vračar.
                    </h5>
                    <a
                      href="https://www.google.com/maps/place/Gaja+Yoga+Studio/@44.7950293,20.4669304,17z/data=!3m1!4b1!4m6!3m5!1s0x475a7118c33511cf:0x649b9f967376ce86!8m2!3d44.7950293!4d20.4695053!16s%2Fg%2F11vf3f8xmc?entry=ttu"
                      className="btn btn-small btn-style-6"
                      target={ '_blank'}
                    >
                      Otvorite na mapi
                    </a>
                  </div>
                  <div className="info-item">
                    <i className="licon-telephone"/>
                    <div className="wrapper">
                      <span className="info-cat">Telefon</span>
                    </div>
                    <h5 className="info-title" content="telephone=no">
                      <a href={ 'tel:' + '38162544168' }>+381 62 544 168</a>
                    </h5>
                  </div>
                  <div className="info-item">
                    <i className="licon-at-sign"/>
                    <div className="wrapper">
                      <span className="info-cat">Email</span>
                    </div>
                    <h5 className="info-title">
                      <a href={ 'mailto:' + 'info@gajayoga.rs'} >info@gajayoga.rs</a>
                    </h5>
                  </div>
                  <div className="info-item">
                    <i className="licon-clock3"/>
                    <div className="wrapper">
                      <span className="info-cat">Radno vreme</span>
                    </div>
                    <h5 className="info-title">
                      Ponedeljak - Petak: <br/> 7 - 21 h
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GeoMap/>
      </div>
      {/* - - - - - - - - - - - - end Content - - - - - - - - - - - - - - - */ }

      {/* - - - - - - - - - - - - - Footer - - - - - - - - - - - - - - - - */ }

      {/* - - - - - - - - - - - - end Footer - - - - - - - - - - - - - - - */ }

      {/* Wrap in IFs and toggle using JS */ }

      {/*<div id="popup" className="popup">*/ }
      {/*  <div className="popup-inner">*/ }
      {/*    <button type="button" className="close-popup" />*/ }
      {/*    <div className="pricing-tables-holder flex-row item-col-2">*/ }
      {/*      /!* - - - - - - - - - - - - - Pricing Table - - - - - - - - - - - - - - - - *!/*/ }
      {/*      <div className="pricing-col">*/ }
      {/*        <div className="pricing-table style-2">*/ }
      {/*          <div className="label">Introductory Offer</div>*/ }
      {/*          <header className="pt-header">*/ }
      {/*            <div className="section-pre-title style-2">Your First</div>*/ }
      {/*            <h3 className="pt-title">5 Classes</h3>*/ }
      {/*            <div className="section-pre-title style-2">Just</div>*/ }
      {/*            <div className="pt-price">$29</div>*/ }
      {/*            <div className="pt-disc">Students - $23</div>*/ }
      {/*          </header>*/ }
      {/*/ .pt-header */ }
      {/*          <footer className="pt-footer">*/ }
      {/*            <a href="#" className="btn btn-style-3">*/ }
      {/*              Buy Now*/ }
      {/*            </a>*/ }
      {/*            <p>Must use within 2 weeks</p>*/ }
      {/*          </footer>*/ }
      {/*/ .pt-footer */ }
      {/*        </div>*/ }
      {/*      </div>*/ }
      {/*      /!* - - - - - - - - - - - - - End of Pricing Tables - - - - - - - - - - - - - - - - *!/*/ }
      {/*      /!* - - - - - - - - - - - - - Pricing Table - - - - - - - - - - - - - - - - *!/*/ }
      {/*      <div className="pricing-col">*/ }
      {/*        <div className="pricing-table">*/ }
      {/*          <div className="label">Limited Time Offer</div>*/ }
      {/*          <header className="pt-header">*/ }
      {/*            <h4 className="pt-title">*/ }
      {/*              Unlimited <br /> membership*/ }
      {/*            </h4>*/ }
      {/*            <div className="pt-price">$109</div>*/ }
      {/*            <div className="section-pre-title style-2">per month</div>*/ }
      {/*            <div className="pt-disc">Students - $90/mo</div>*/ }
      {/*          </header>*/ }
      {/*/ .pt-header */ }
      {/*          <footer className="pt-footer">*/ }
      {/*            <a href="#" className="btn btn-style-3">*/ }
      {/*              Buy Now*/ }
      {/*            </a>*/ }
      {/*            <p>Minimum 4 month auto-renew commitment</p>*/ }
      {/*          </footer>*/ }
      {/*/ .pt-footer */ }
      {/*        </div>*/ }
      {/*      </div>*/ }
      {/*      /!* - - - - - - - - - - - - - End of Pricing Tables - - - - - - - - - - - - - - - - *!/*/ }
      {/*    </div>*/ }
      {/*  </div>*/ }
      {/*</div>*/ }

      {/*<div id="popup-sign" className="popup var3">*/ }
      {/*  <div className="popup-inner">*/ }
      {/*    <button type="button" className="close-popup" />*/ }
      {/*    <h4 className="title">Sign Up For Free</h4>*/ }
      {/*    <p>*/ }
      {/*      Already have an account?{" "}*/ }
      {/*      <a href="#" className="link-text var2 popup-btn-login">*/ }
      {/*        Login Here*/ }
      {/*      </a>*/ }
      {/*    </p>*/ }
      {/*    <a href="#" className="btn fb-btn btn-big">*/ }
      {/*      Sign Up With Facebook*/ }
      {/*    </a>*/ }
      {/*    <p className="content-element2">OR</p>*/ }
      {/*    <form className="content-element2">*/ }
      {/*      <input type="text" placeholder="Enter Your Email Address" />*/ }
      {/*      <input type="text" placeholder="Password" />*/ }
      {/*      <a href="#" className="btn btn-style-3 btn-big">*/ }
      {/*        Join Free Now!*/ }
      {/*      </a>*/ }
      {/*    </form>*/ }
      {/*    <p className="text-size-small">*/ }
      {/*      By signing up you agree to{" "}*/ }
      {/*      <a href="#" className="link-text">*/ }
      {/*        Terms of Service*/ }
      {/*      </a>*/ }
      {/*    </p>*/ }
      {/*  </div>*/ }
      {/*</div>*/ }
      {/*<div id="popup-login" className="popup var3">*/ }
      {/*  <div className="popup-inner">*/ }
      {/*    <button type="button" className="close-popup" />*/ }
      {/*    <h4 className="title">Login</h4>*/ }
      {/*    <p>*/ }
      {/*      Don't have an account yet?*/ }
      {/*      <a href="#" className="link-text var2 popup-btn-sign">*/ }
      {/*        JOIN FOR FREE*/ }
      {/*      </a>*/ }
      {/*    </p>*/ }
      {/*    <a href="#" className="btn fb-btn btn-big">*/ }
      {/*      {" "}*/ }
      {/*      Login With Facebook*/ }
      {/*    </a>*/ }
      {/*    <p className="content-element2">OR</p>*/ }
      {/*    <form className="content-element1">*/ }
      {/*      <input type="text" placeholder="Enter Your Email Address" />*/ }
      {/*      <input type="text" placeholder="Password" />*/ }
      {/*      <a href="#" className="btn btn-style-3 btn-big">*/ }
      {/*        Login*/ }
      {/*      </a>*/ }
      {/*      <div className="input-wrapper">*/ }
      {/*        <input type="checkbox" id="checkbox11" name="checkbox" />*/ }
      {/*        <label htmlFor="checkbox">Remember me</label>*/ }
      {/*      </div>*/ }
      {/*    </form>*/ }
      {/*    <p className="text-size-small">*/ }
      {/*      <a href="#" className="link-text">*/ }
      {/*        Forgot your password?*/ }
      {/*      </a>*/ }
      {/*    </p>*/ }
      {/*  </div>*/ }
      {/*</div>*/ }
    </div>
  );
}

export default Landing;
