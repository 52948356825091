import { Outlet } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import React from 'react';

function ApplicationWrapper({ children = undefined } : { children?: React.ReactNode }) {
  return (
    <>
      <div id="wrapper" className="wrapper-container">
        <Header/>
        {/* If children exist, render them */}
        {children}
        <Outlet/>
        <Footer/>
      </div>
    </>
  );
}

export default ApplicationWrapper;
