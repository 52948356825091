function Footer() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <>
      <footer id="footer" className="footer" data-bg="/images/1920x390_bg1.jpg">
        <div className="logo">
          <img src="/images/logo.png" alt=""/>
        </div>
        <div className="container wide-style">
          {/* main footer */ }
          <div className="main-footer">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="widget">
                  <h6 className="widget-title">Pridužite se našoj email listi</h6>
                  <form id="newsletter" className="newsletter style-2">
                    <input
                      type="email"
                      name="newsletter-email"
                      placeholder="Unesite vašu email adresu"
                    />
                    <button
                      type="submit"
                      datatype="submit"
                      className="btn btn-big btn-style-3"
                    >
                      Prijavite se
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-4 offset-lg-4 col-md-12">
                <div className="widget">
                  <h6 className="widget-title">Povežimo se</h6>
                  <ul className="social-icons">
                    <li>
                      <a href="https://www.instagram.com/gajayogastudio/">
                        <i className="icon-instagram-5"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/gajayogastudio/">
                        <i className="icon-facebook"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/gajayogastudio/">
                        <i className="icon-twitter"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/gajayogastudio/">
                        <i className="icon-youtube-play"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com/gajayogastudio/">
                        <i className="icon-pinterest"/>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/gajayogastudio/">
                        <i className="icon-linkedin"/>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <ul className="menu-list">
            <li>
              <a href="/">Početna</a>
            </li>
            <li>
              <a href="/casovi/raspored">Raspored</a>
            </li>
            <li>
              <a href="/casovi/cenovnik">Cenovnik</a>
            </li>
            <li>
              <a href="/dogadjaji">Dogadjaji</a>
            </li>
            <li>
              <a href="/studio/kontakt">Kontakt</a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>
            <li>
              <a href="/shop">Prodavnica</a>
            </li>
          </ul>
          <div className="copyright">
            <p>Copyright © { getCurrentYear() } Gaja Yoga Studio. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
