import './App.css'

import router from './router/index';
import { RouterProvider } from 'react-router-dom';
// @ts-ignore
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = 'pk.eyJ1IjoiZGFya29kIiwiYSI6ImNsbjhoemk1MzAxZWwyc282Y3BrcHRhZ2oifQ.WeSTwnz00jpk65-U-ETzUA';

function App() {

  return (
    <>
      <RouterProvider router={ router }/>
    </>
  );
}

export default App
