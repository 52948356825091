// @ts-ignore
import mapboxgl from '!mapbox-gl';
import { useEffect, useRef, useState } from 'react'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = 'pk.eyJ1IjoiZGFya29kIiwiYSI6ImNsbjhoemk1MzAxZWwyc282Y3BrcHRhZ2oifQ.WeSTwnz00jpk65-U-ETzUA';


function GeoMap() {

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lat, setLng] = useState(44.795035209314634);
  const [lng, setLat] = useState(20.469506273165315);
  const [zoom, setZoom] = useState(16);

  useEffect(() => {
    if (map.current) {
      return; // initialize a map only once
    }

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom
    });

    // Create a default Marker and add it to the map.
    const studioLocation = new mapboxgl.Marker()
      .setLngLat([lng, lat])
      .addTo(map.current);
  });

  return (
    <div>
      <div style={ {height: '400px'} } ref={ mapContainer } className="map-container"/>
    </div>
  );
}

export default GeoMap;
