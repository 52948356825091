function AboutUs() {
  return (
    <div>
      <>
        {/* - - - - - - - - - - - - - Breadcrumbs - - - - - - - - - - - - - - - - */}
        <div className="breadcrumbs-wrap">
          <div className="container">
            <h1 className="page-title">O nama</h1>
            <ul className="breadcrumbs">
              <li>
                <a href="/">Početna</a>
              </li>
              <li>O Nama</li>
            </ul>
          </div>
        </div>
        {/* - - - - - - - - - - - - end Breadcrumbs - - - - - - - - - - - - - - - */}
        {/* - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - */}
        <div id="content">
          {/* Page section */}
          <div className="page-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <h3 className="section-title">O našem studiju</h3>
                  <div className="content-element3">
                    <p>
                      Sa ovim yoga studiom smo hteli da napravimo nesto novo i posebno.
                      Premium i luksuzno iskustvo koje ce vam omoguciti da se osecate kao kod kuce.
                      Nasa misija je da vam pruzimo najbolje moguce iskustvo i da vam pomognemo da ostvarite svoje ciljeve.
                    </p>
                    <p>
                      Paznjivo birmo i instruktore kao i opremu koju koristimo.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="video-holder">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/NpEaa2P7qZI?si=j74U7RpTiQxrBZm1&amp;controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Page section */}
          <div className="page-section-bg">
            <div className="container">
              <div className="content-element5">
                <h3 className="align-center">
                  6 Razloga zašto da vežbate u našem studiju
                </h3>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="services">
                    <div className="secvice-item">
                      <h5 className="service-title">Zdravlje i dobrobit</h5>
                      <p>
                        Joga je sjajan način da se ojačaju mišići, poboljša fleksibilnost i smanji stres.
                      </p>
                    </div>
                    <div className="secvice-item">
                      <h5 className="service-title">Zajednica</h5>
                      <p>
                        Joga je društvena aktivnost koja može da pomogne ljudima da se povežu sa drugima koji dele njihov interes.
                      </p>
                    </div>
                    <div className="secvice-item">
                      <h5 className="service-title">Pronađite mir i opuštanje</h5>
                      <p>
                        Joga može da bude odličan način da se opustiš i oslobodiš stresa.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="services">
                    <div className="secvice-item">
                      <h5 className="service-title">Zabava</h5>
                      <p>
                        Joga može da bude zabavan i izazovan način da se održiš u formi.
                      </p>
                    </div>
                    <div className="secvice-item">
                      <h5 className="service-title">Osećajte se bolje u svom telu</h5>
                      <p>
                        Joga može da pomogne ljudima da se osećaju bolje u svom telu, kako fizički tako i emocionalno.
                      </p>
                    </div>
                    <div className="secvice-item">
                      <h5 className="service-title">Opustite um i telo</h5>
                      <p>
                        Joga je odličan način da se opusti um i telo. Redovnim vežbanjem joge možeš da smanjiš stres,
                        anksioznost i depresiju. Joga može takođe da pomogne u poboljšanju sna i koncentracije.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Page section */}
          <div className="page-section">
            <div className="container">
              <div className="info-boxes flex-row item-col-3">
                <div className="info-box">
                  <div className="box-img">
                    <img src="/images/360x234_img1.jpg" alt="" />
                  </div>
                  <h4 className="box-title">
                    <a href="#">Naša misija</a>
                  </h4>
                  <p>
                    Da Vam pružimo okruzenje i uslove za postizanje daljeg razvoja i napretka u svakom smislu.
                  </p>
                </div>
                <div className="info-box">
                  <div className="box-img">
                    <img src="/images/360x234_img2.jpg" alt="" />
                  </div>
                  <h4 className="box-title">
                    <a href="#">Naša vizija</a>
                  </h4>
                  <p>
                    Da postanemo najbolji studio u regionu i da našim vežbačima pružimo najbolje moguće iskustvo.
                  </p>
                </div>
                <div className="info-box">
                  <div className="box-img">
                    <img src="/images/360x234_img3.jpg" alt="" />
                  </div>
                  <h4 className="box-title">
                    <a href="#">Naše vrednosti</a>
                  </h4>
                  <p>
                    Kvalitet, posvećenost, ljubaznost, poštovanje, razumevanje, podrška, timski rad, inovativnost, kreativnost.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Page section */}
          <div
            className="page-section-bg parallax-section"
            data-bg="/images/1920x990_bg2.jpg"
          >
            <div className="container">
              <div className="content-element5">
                <h3 className="align-center">Šta naši vežbači kažu</h3>
              </div>
              <div className="carousel-type-1">
                <div
                  className="owl-carousel testimonial-holder style-2"
                  data-max-items={1}
                  data-autoplay="true"
                >
                  {/* Slide */}
                  <div className="item-carousel">
                    {/* Carousel Item */}
                    {/* - - - - - - - - - - - - - Testimonial - - - - - - - - - - - - - - - - */}
                    <div className="testimonial">
                      <blockquote>
                        <h5 className="title">Najbolji deo moga dana!</h5>
                        <p>
                          TBA
                        </p>
                      </blockquote>
                      <div className="author-box">
                  <span className="author-icon">
                    <i className="svg" />
                  </span>
                        <div className="author-info">
                          <div className="author">Nikola M.</div>
                          {/*<a href="#" className="author-position">*/}
                          {/*  Santa Monica, CA*/}
                          {/*</a>*/}
                        </div>
                      </div>
                    </div>
                    {/* /Carousel Item */}
                  </div>
                  {/* /Slide */}
                  {/* Slide */}
                  <div className="item-carousel">
                    {/* Carousel Item */}
                    {/* - - - - - - - - - - - - - Testimonial - - - - - - - - - - - - - - - - */}
                    <div className="testimonial">
                      <blockquote>
                        <h5 className="title">Energija i mir u isto vreme!</h5>
                        <p>
                          TBA
                        </p>
                      </blockquote>
                      <div className="author-box">
                  <span className="author-icon">
                    <i className="svg" />
                  </span>
                        <div className="author-info">
                          <div className="author">Milica L.</div>
                          {/*<a href="#" className="author-position">*/}
                          {/*  San Dirgo, CA*/}
                          {/*</a>*/}
                        </div>
                      </div>
                    </div>
                    {/* /Carousel Item */}
                  </div>
                  {/* /Slide */}
                  {/* Slide */}
                  <div className="item-carousel">
                    {/* Carousel Item */}
                    {/* - - - - - - - - - - - - - Testimonial - - - - - - - - - - - - - - - - */}
                    <div className="testimonial">
                      <blockquote>
                        <h5 className="title">Najbolji način da započnem i završim dan</h5>
                        <p>
                          TBA
                        </p>
                      </blockquote>
                      <div className="author-box">
                  <span className="author-icon">
                    <i className="svg" />
                  </span>
                        <div className="author-info">
                          <div className="author">Nataša R.</div>
                          {/*<a href="#" className="author-position">*/}
                          {/*  Santa Monica, CA*/}
                          {/*</a>*/}
                        </div>
                      </div>
                    </div>
                    {/* /Carousel Item */}
                  </div>
                  {/* /Slide */}
                </div>
              </div>
            </div>
          </div>
          {/* Page section */}
          {/*<div className="page-section">*/}
          {/*  <div className="container">*/}
          {/*    <div className="content-element5">*/}
          {/*      <h3 className="align-center">Unutar studija</h3>*/}
          {/*    </div>*/}
          {/*    <div className="carousel-type-2">*/}
          {/*      <div*/}
          {/*        className="owl-carousel portfolio-holder"*/}
          {/*        data-max-items={3}*/}
          {/*        data-item-margin={30}*/}
          {/*      >*/}
          {/*        /!* Owl item *!/*/}
          {/*        <div className="project">*/}
          {/*          /!* - - - - - - - - - - - - - Project Image - - - - - - - - - - - - - - - - *!/*/}
          {/*          <div className="project-image">*/}
          {/*            <img src="/images/360x286_img1.jpg" alt="" />*/}
          {/*            <a*/}
          {/*              href="/images/360x286_img1.jpg"*/}
          {/*              className="project-link"*/}
          {/*              data-fancybox="group"*/}
          {/*            />*/}
          {/*          </div>*/}
          {/*          /!* - - - - - - - - - - - - - End of Project Image - - - - - - - - - - - - - - - - *!/*/}
          {/*        </div>*/}
          {/*        /!* Owl item *!/*/}
          {/*        <div className="project">*/}
          {/*          /!* - - - - - - - - - - - - - Project Image - - - - - - - - - - - - - - - - *!/*/}
          {/*          <div className="project-image">*/}
          {/*            <img src="/images/360x286_img2.jpg" alt="" />*/}
          {/*            <a*/}
          {/*              href="/images/360x286_img2.jpg"*/}
          {/*              className="project-link"*/}
          {/*              data-fancybox="group"*/}
          {/*            />*/}
          {/*          </div>*/}
          {/*          /!* - - - - - - - - - - - - - End of Project Image - - - - - - - - - - - - - - - - *!/*/}
          {/*        </div>*/}
          {/*        /!* Owl item *!/*/}
          {/*        <div className="project">*/}
          {/*          /!* - - - - - - - - - - - - - Project Image - - - - - - - - - - - - - - - - *!/*/}
          {/*          <div className="project-image">*/}
          {/*            <img src="/images/360x286_img3.jpg" alt="" />*/}
          {/*            <a*/}
          {/*              href="/images/360x286_img3.jpg"*/}
          {/*              className="project-link"*/}
          {/*              data-fancybox="group"*/}
          {/*            />*/}
          {/*          </div>*/}
          {/*          /!* - - - - - - - - - - - - - End of Project Image - - - - - - - - - - - - - - - - *!/*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        {/* - - - - - - - - - - - - end Content - - - - - - - - - - - - - - - */}
      </>
    </div>
  );
}

export default AboutUs;
