function Faq() {
  return (
    <>
      {/* - - - - - - - - - - - - - Breadcrumbs - - - - - - - - - - - - - - - - */ }
      <div className="breadcrumbs-wrap">
        <div className="container">
          <h1 className="page-title">Često postavljena pitanja</h1>
          <ul className="breadcrumbs">
            <li>
              <a href="/">Početna</a>
            </li>
            <li>Često postavljena pitanja</li>
          </ul>
        </div>
      </div>
      {/* - - - - - - - - - - - - end Breadcrumbs - - - - - - - - - - - - - - - */ }
      {/* - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - */ }
      <div id="content" className="page-content-wrap">
        <div className="container">
          <div className="row">
            <main id="main" className="col-lg-8 col-md-12">
              <div className="accordion style-2">
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title">Sta je to Yoga?</h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title active">
                    Ja sam nov u Yogi. Gde da počnem?
                  </h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title">Koje časove nudite?</h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title">Šta treba da ponesem na čas?</h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title">Šta treba da nosim od garderobe na trening?</h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title">Šta da jedem pred čas?</h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title">Da li nudite privatne ili individualne časove?</h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title">
                    Da li imate negde gde mogu da se presvučem?
                  </h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title">Šta ako kasnim na čas?</h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title">Da li imate obezbedjen parking?</h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title">Koje stepen iskustva sa yogom treba da imam da bi dolazio kod vas?</h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title">Koliko je studenata u grupu?</h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
                {/*accordion item*/ }
                <div className="accordion-item">
                  <h5 className="a-title">
                    Da li imate specijalizovane grupe (senior/junior/etc...)?
                  </h5>
                  <div className="a-content">
                    <p>
                      Sed laoreet aliquam leo. Ut tellus dolor, dapibus eget,
                      elementum vel, cursus eleifend, elit. Aenean auctor wisi et
                      urna. Aliquam erat volutpat. Duis ac turpis. Integer rutrum
                      ante eu lacus. Vestibulum libero nisl, porta vel, scelerisque
                      eget, malesuada at, neque. Vivamus eget nibh. Etiam cursus leo
                      vel metus. Nulla facilisi. Aenean nec eros. Vestibulum ante
                      ipsum primis in faucibus orci luctus et ultrices posuere
                      cubilia Curae.
                    </p>
                  </div>
                </div>
              </div>
            </main>
            <aside id="sidebar" className="col-lg-4 col-md-12">
              <div className="widget">
                <h6 className="widget-title">Kontaktirajte nas</h6>
                <p className="text-size-small">
                  Niste pronašli odgovor za vaše pitanje? Kontaktirajte nas i rado ćemo vam pomoći.
                </p>
                <form id="contact-form" className="contact-form style-2">
                  <div className="contact-item">
                    <label className="required">Your Name</label>
                    <input type="text" name="cf-name" required={ true }/>
                  </div>
                  <div className="contact-item">
                    <label className="required">Your Email</label>
                    <input type="email" name="cf-email" required={ true }/>
                  </div>
                  <div className="contact-item">
                    <label>Phone</label>
                    <input type="tel" name="cf-phone"/>
                  </div>
                  <div className="contact-item">
                    <label>Your Message</label>
                    <textarea rows={ 4 } name="cf-message" defaultValue={ '' }/>
                  </div>
                  <div className="contact-item">
                    <button
                      type="submit"
                      className="btn btn-style-3"
                      datatype="submit"
                    >
                      Pošalji
                    </button>
                  </div>
                </form>
              </div>

              {/* Re-add once blog is up and running */}
              { false && (
                <>
                  <div className="widget">
                    <h6 className="widget-title">Latest Posts</h6>
                    <div className="entry-box entry-small style-2">
                      {/* - - - - - - - - - - - - - Entry - - - - - - - - - - - - - - - - */ }
                      <div className="entry">
                        {/* - - - - - - - - - - - - - Entry attachment - - - - - - - - - - - - - - - - */ }
                        <div className="thumbnail-attachment">
                          <a href="#">
                            <img src="/images/100x78_img1.jpg" alt=""/>
                          </a>
                        </div>
                        {/* - - - - - - - - - - - - - Entry body - - - - - - - - - - - - - - - - */ }
                        <div className="entry-body">
                          <h6 className="entry-title">
                            <a href="#">Mauris Accumsan Nulla Vel Diam</a>
                          </h6>
                          <div className="entry-meta">
                            <time className="entry-date" dateTime="2018-11-18">
                              November 18, 2018
                            </time>
                          </div>
                        </div>
                      </div>
                      {/* - - - - - - - - - - - - - Entry - - - - - - - - - - - - - - - - */ }
                      <div className="entry">
                        {/* - - - - - - - - - - - - - Entry attachment - - - - - - - - - - - - - - - - */ }
                        <div className="thumbnail-attachment">
                          <a href="#">
                            <img src="/images/100x78_img2.jpg" alt=""/>
                          </a>
                        </div>
                        {/* - - - - - - - - - - - - - Entry body - - - - - - - - - - - - - - - - */ }
                        <div className="entry-body">
                          <h6 className="entry-title">
                            <a href="#">Ut Pharetra Augue Nec Augue</a>
                          </h6>
                          <div className="entry-meta">
                            <time className="entry-date" dateTime="2018-12-21">
                              Dec 21, 2018, 07:00 AM
                            </time>
                          </div>
                        </div>
                      </div>
                      {/* - - - - - - - - - - - - - Entry - - - - - - - - - - - - - - - - */ }
                      <div className="entry">
                        {/* - - - - - - - - - - - - - Entry attachment - - - - - - - - - - - - - - - - */ }
                        <div className="thumbnail-attachment">
                          <a href="#">
                            <img src="/images/100x78_img3.jpg" alt=""/>
                          </a>
                        </div>
                        {/* - - - - - - - - - - - - - Entry body - - - - - - - - - - - - - - - - */ }
                        <div className="entry-body">
                          <h6 className="entry-title">
                            <a href="#">Donec Eget Tellus Non Erat Lacinia Fermentum</a>
                          </h6>
                          <div className="entry-meta">
                            <time className="entry-date" dateTime="2018-12-21">
                              Dec 21, 2018, 07:00 AM
                            </time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) }
            </aside>
          </div>
        </div>
      </div>
      {/* - - - - - - - - - - - - end Content - - - - - - - - - - - - - - - */ }
    </>

  );
}

export default Faq;
