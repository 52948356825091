import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// CSS Imports
import './assets/font/demo-files/demo.css';

import './plugins/fancybox/jquery.fancybox.css';
import './plugins/revolution/css/settings.css';
import './plugins/revolution/css/layers.css';
import './plugins/revolution/css/navigation.css';

import './styles/bootstrap-grid.min.css';
import './styles/fontello.css';
import './styles/owl.carousel.css';
import './styles/style.css';
import './styles/responsive.css';
import 'mapbox-gl/dist/mapbox-gl.css';


// JS Imports
// Certain imports are commented out and imported in index.html to ensure libraries get access to the right DOM elements
// and global state.

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
