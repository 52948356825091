function YogaTypes() {
  return (
    <>
      <div className="breadcrumbs-wrap">
        <div className="container">
          <h1 className="page-title">Vrste Yoga</h1>
          <ul className="breadcrumbs">
            <li>
              <a href="/">Početna</a>
            </li>
            <li>Vrste Yoge</li>
          </ul>
        </div>
      </div>
      <div id="content">
        <div className="page-section">
          <div className="class-desc">
            <div className="container">
              <div className="content-element10">
                <div className="row row-2 flex-wrap-reverse">
                  <div className="col-md-6 col-sm-12">
                    <h3 className="section-title">Hatha</h3>
                    <p>
                      Vivamus eget nibh. Etiam cursus leo vel metus. Nulla facilisi.
                      Aenean nec eros. Vestibulum ante ipsum primis in faucibus orci
                      luctus et ultrices posuere cubilia curae.{" "}
                    </p>
                    <p>
                      Suspendisse sollicitudin velit sed leo. Ut pharetra augue nec
                      augue. Nam elit agna, endrerit sit amet, tincidunt ac, viverra
                      sed, nulla. Donec porta diam eu massa.{" "}
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <img src="/images/555x338_img2.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="content-element10">
                <div className="row row-2">
                  <div className="col-md-6 col-sm-12">
                    <img src="/images/555x338_img3.jpg" alt="" />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <h3 className="section-title">Vinyasa</h3>
                    <p>
                      Vivamus eget nibh. Etiam cursus leo vel metus. Nulla facilisi.
                      Aenean nec eros. Vestibulum ante ipsum primis in faucibus orci
                      luctus et ultrices posuere cubilia curae.{" "}
                    </p>
                    <p>
                      Suspendisse sollicitudin velit sed leo. Ut pharetra augue nec
                      augue. Nam elit agna, endrerit sit amet, tincidunt ac, viverra
                      sed, nulla. Donec porta diam eu massa.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="content-element10">
                <div className="row row-2 flex-wrap-reverse">
                  <div className="col-md-6 col-sm-12">
                    <h3 className="section-title">Prenatal</h3>
                    <p>
                      Vivamus eget nibh. Etiam cursus leo vel metus. Nulla facilisi.
                      Aenean nec eros. Vestibulum ante ipsum primis in faucibus orci
                      luctus et ultrices posuere cubilia curae.{" "}
                    </p>
                    <p>
                      Suspendisse sollicitudin velit sed leo. Ut pharetra augue nec
                      augue. Nam elit agna, endrerit sit amet, tincidunt ac, viverra
                      sed, nulla. Donec porta diam eu massa.{" "}
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <img src="/images/555x338_img4.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="content-element10">
                <div className="row row-2">
                  <div className="col-md-6 col-sm-12">
                    <img src="/images/555x338_img5.jpg" alt="" />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <h3 className="section-title">Senior</h3>
                    <p>
                      Vivamus eget nibh. Etiam cursus leo vel metus. Nulla facilisi.
                      Aenean nec eros. Vestibulum ante ipsum primis in faucibus orci
                      luctus et ultrices posuere cubilia curae.{" "}
                    </p>
                    <p>
                      Suspendisse sollicitudin velit sed leo. Ut pharetra augue nec
                      augue. Nam elit agna, endrerit sit amet, tincidunt ac, viverra
                      sed, nulla. Donec porta diam eu massa.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="content-element10">
                <div className="row row-2 flex-wrap-reverse">
                  <div className="col-md-6 col-sm-12">
                    <h3 className="section-title">Meditacija</h3>
                    <p>
                      Vivamus eget nibh. Etiam cursus leo vel metus. Nulla facilisi.
                      Aenean nec eros. Vestibulum ante ipsum primis in faucibus orci
                      luctus et ultrices posuere cubilia curae.{" "}
                    </p>
                    <p>
                      Suspendisse sollicitudin velit sed leo. Ut pharetra augue nec
                      augue. Nam elit agna, endrerit sit amet, tincidunt ac, viverra
                      sed, nulla. Donec porta diam eu massa.{" "}
                    </p>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <img src="/images/555x338_img4.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="call-out var2 bg-section" data-bg="/images/1816x234_bg1.jpg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-12">
                <h2 className="call-title">Započnite vaše vežbanje danas!</h2>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="align-right">
                  <a href="/casovi/raspored" className="btn btn-style-6 btn-lg">
                    Pogledajte naš raspored
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default YogaTypes;
