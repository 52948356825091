function ClassTypes() {
  return (
    <>
      <div className="breadcrumbs-wrap">
        <div className="container">
          <h1 className="page-title">Vrste Časova</h1>
          <ul className="breadcrumbs">
            <li>
              <a href="/">Početna</a>
            </li>
            <li>Vrste Časova</li>
          </ul>
        </div>
      </div>
      <div id="content">
        <div className="page-section">
          <div className="class-desc">
            <div className="container">
              <div className="content-element10">
                <div className="row row-2 flex-wrap-reverse">
                  <div className="col-md-6 col-sm-12">
                    <h3 className="section-title">Rise and Shine</h3>
                    <ul>
                      <li>Jutarnji program</li>
                      <li>Zapocnite dan sa punom energijom</li>
                      <li>Pokrenite vase cakrice</li>
                      <li>Pokrecemo endokrini sistem, napunis se energijom, ako ste lose spavali probijate te barijere,
                        bolja koncetracija u toku dana, budite spremi za ostatak dana.`
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <img src="/images/555x338_img2.jpg" alt=""/>
                  </div>
                </div>
              </div>
              <div className="content-element10">
                <div className="row row-2">
                  <div className="col-md-6 col-sm-12">
                    <img src="/images/555x338_img3.jpg" alt=""/>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <h3 className="section-title">Hatha a great day</h3>
                    <ul>
                      <li>Smanjenje stresa i anksioznosti</li>
                      <li>Mirniji um</li>
                      <li>Miran I stalozen tok vezbi</li>
                      <li>Akcenat na ledja I istezanja</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="content-element10">
                <div className="row row-2 flex-wrap-reverse">
                  <div className="col-md-6 col-sm-12">
                    <h3 className="section-title">Happy hour</h3>
                    <ul>
                      <li>
                        Otklonite nervoza i stress sa posla
                      </li>
                      <li>
                        Smirite se pred kraj dana
                      </li>
                      <li>
                        Poboljsajte san i maksimalno se odmorite
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <img src="/images/555x338_img4.jpg" alt=""/>
                  </div>
                </div>
              </div>
              <div className="content-element10">
                <div className="row row-2">
                  <div className="col-md-6 col-sm-12">
                    <img src="/images/555x338_img5.jpg" alt=""/>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <h3 className="section-title">After Party</h3>
                    <ul>
                      <li>
                        Zvucite sve sto vam je ostalo od snage u ovom dinamicnom treningu
                      </li>
                      <li>
                        Fokus su vezbe snage
                      </li>
                      <li>
                        Izazovne i teske asane
                      </li>
                      <li>
                        Probijanja vasih granica
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="content-element10">
                <div className="row row-2 flex-wrap-reverse">
                  <div className="col-md-6 col-sm-12">
                    <h3 className="section-title">Meditacija</h3>
                    <ul>
                      <li>
                        Smirite um
                      </li>
                      <li>
                        Poboljsajte koncentraciju
                      </li>
                      <li>
                        Poboljsajte san
                      </li>
                      <li>
                        Poboljsajte kvalitet zivota
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <img src="/images/555x338_img4.jpg" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="call-out var2 bg-section" data-bg="/images/1816x234_bg1.jpg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-12">
                <h2 className="call-title">Započnite vaše vežbanje danas!</h2>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="align-right">
                  <a href="/casovi/raspored" className="btn btn-style-6 btn-lg">
                    Pogledajte naš raspored
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClassTypes;
