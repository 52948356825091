import ClassSchedule from '../../../components/ClassSchedule/ClassSchedule';

function ClassSchedulePage() {
  return (
    <>
      {/* - - - - - - - - - - - - - Breadcrumbs - - - - - - - - - - - - - - - - */}
      <div className="breadcrumbs-wrap">
        <div className="container">
          <h1 className="page-title">Raspored časova</h1>
          <ul className="breadcrumbs">
            <li>
              <a href="/">Početna</a>
            </li>
            <li>Raspored časova</li>
          </ul>
        </div>
      </div>
      <ClassSchedule/>
    </>

  )
}

export default ClassSchedulePage;
