function Pricing() {
  return (
    <>
      {/* - - - - - - - - - - - - - Breadcrumbs - - - - - - - - - - - - - - - - */}
      <div className="breadcrumbs-wrap">
        <div className="container">
          <h1 className="page-title">Cenovnik</h1>
          <ul className="breadcrumbs">
            <li>
              <a href="/">Početna</a>
            </li>
            <li>Cenovnik</li>
          </ul>
        </div>
      </div>
      {/* - - - - - - - - - - - - end Breadcrumbs - - - - - - - - - - - - - - - */}
      {/* - - - - - - - - - - - - - Content - - - - - - - - - - - - - - - - */}
      <div id="content">
        <div className="page-section">
          <div className="container">
            <div className="pricing-tables-holder flex-row item-col-3">
              {/* - - - - - - - - - - - - - Pricing Table - - - - - - - - - - - - - - - - */}
              <div className="pricing-col">
                <div className="pricing-table">
                  <header className="pt-header">
                    <div className="pt-type">Osnovni paket</div>
                    <div className="pt-price">5000RSD</div>
                    <div className="pt-period">mesečno</div>
                  </header>
                  {/*/ .pt-header */}
                  <ul className="custom-list">
                    <li>8 termina mesečno</li>
                    <li>Trening od 75 minuta</li>
                    <li>Termini važe 31 dan nakon uplate</li>
                    <li>Dostupna Manduka oprema u studiju</li>
                    <li>Bilo koji termini iz grupne selekcije</li>
                  </ul>
                  <footer className="pt-footer">
                    <a href="/studio/kontakt" className="btn btn-style-3">
                      Prijavi se
                    </a>
                  </footer>
                  {/*/ .pt-footer */}
                </div>
              </div>
              {/* - - - - - - - - - - - - - End of Pricing Tables - - - - - - - - - - - - - - - - */}
              {/* - - - - - - - - - - - - - Pricing Table - - - - - - - - - - - - - - - - */}
              <div className="pricing-col">
                <div className="pricing-table style-2">
                  <div className="label">Preporučeno</div>
                  <header className="pt-header">
                    <div className="pt-type">Work Hard & Play Hard</div>
                    <div className="pt-price">7500RSD</div>
                    <div className="pt-period">mesečno</div>
                  </header>
                  {/*/ .pt-header */}
                  <ul className="custom-list">
                    <li>12 termina mesečno</li>
                    <li>Trening od 75 minuta</li>
                    <li>Termini važe 45 dana nakon uplate</li>
                    <li>Dostupna Manduka oprema u studiju</li>
                    <li>Bilo koji termini iz grupne selekcije</li>
                    <li>50% popusta na jedan individualni termin mesecno</li>
                  </ul>
                  <footer className="pt-footer">
                    <a href="/studio/kontakt" className="btn btn-style-3">
                      Prijavi se
                    </a>
                  </footer>
                  {/*/ .pt-footer */}
                </div>
              </div>
              {/* - - - - - - - - - - - - - End of Pricing Tables - - - - - - - - - - - - - - - - */}
              {/* - - - - - - - - - - - - - Pricing Table - - - - - - - - - - - - - - - - */}
              <div className="pricing-col">
                <div className="pricing-table">
                  <header className="pt-header">
                    <div className="pt-type">The One and Only</div>
                    <div className="pt-price">Kontaktirajte nas</div>
                    <div className="pt-period">po treningu</div>
                  </header>
                  {/*/ .pt-header */}
                  <ul className="custom-list">
                    <li>Individualni treninzi</li>
                    <li>Dužina treninga po vašoj želji</li>
                    <li>Mogućnost korišćenja Manduka opreme iz studija</li>
                    <li>Fokusirane vežbe za oblasti na kojima želite da radite</li>
                    <li>Trening specificno optimiziran za Vas</li>
                  </ul>
                  <footer className="pt-footer">
                    <a href="/studio/kontakt" className="btn btn-style-3">
                      Prijavi se
                    </a>
                  </footer>
                  {/*/ .pt-footer */}
                </div>
              </div>
              {/* - - - - - - - - - - - - - End of Pricing Tables - - - - - - - - - - - - - - - - */}
            </div>
          </div>
        </div>
        <div className="call-out bg-section" data-bg="/images/1920x286_bg1.jpg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 col-md-12">
                <div className="align-center">
                  <h2 className="call-title var2">Poklon kartice</h2>
                  <p>
                    Kupite personalizovanu poklon karticu i obradujte vaše prijatelje i porodicu!
                  </p>
                  <a href="/studio/kontakt" className="btn btn-style-6 btn-big">
                    Poručite
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* - - - - - - - - - - - - end Content - - - - - - - - - - - - - - - */}
    </>
  );
}

export default Pricing;
