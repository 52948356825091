import { createBrowserRouter } from 'react-router-dom';

// Route imports
import Landing from '../pages/Landing/Landing';
import ApplicationWrapper from '../pages/wrappers/ApplicationWrapper';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import AboutUs from '../pages/Studio/AboutUs/AboutUs';
import StudioWrapper from '../pages/Studio/StudioWrapper/StudioWrapper';
import Pricing from '../pages/Classes/Pricing/Pricing';
import Faq from '../pages/Studio/FAQ/Faq';
import ClassesWrapper from '../pages/Classes/ClassesWrapper/ClassesWrapper';
import ClassSchedulePage from '../pages/Classes/ClassSchedule/ClassSchedulePage';
import Contact from '../pages/Studio/Contact/Contact';
import ClassTypes from '../pages/Classes/ClassTypes/ClassTypes';
import YogaTypes from '../pages/Classes/YogaTypes/YogaTypes';
import GalleryOverview from '../pages/Gallery/GalleryOverview/GalleryOverview';

const router = createBrowserRouter([
  {
    path: '/',
    element: <ApplicationWrapper/>,
    errorElement: <PageNotFound />,
    children: [
      {
        path: '/',
        element: <Landing/>,
      },
      {
        path: '/studio',
        element: <StudioWrapper/>,
        children: [
          {
            path: '/studio/o-nama',
            element: <AboutUs/>,
          },
          {
            path: '/studio/tim',
            element: <div>Tim</div>,
          },
          {
            path: '/studio/faq',
            element: <Faq/>,
          },
          {
            path: '/studio/kontakt',
            element: <Contact/>,
          },
        ]
      },
      {
        path: '/casovi',
        element: <ClassesWrapper/>,
        children: [
          {
            path: '/casovi/vrste-casova',
            element: <ClassTypes/>,
          },
          {
            path: '/casovi/raspored',
            element: <ClassSchedulePage/>,
          },
          {
            path: '/casovi/vrste-yoge',
            element: <YogaTypes/>,
          },
          {
            path: '/casovi/online',
            element: <div>Online</div>,
          },
          {
            path: '/casovi/izazovi',
            element: <div>Izazovi</div>,
          },
          {
            path: '/casovi/cenovnik',
            element: <Pricing/>,
          }
        ]
      },
      {
        path: '/galerija',
        element: <GalleryOverview/>,
      }
    ]
  },
]);

export default router;
