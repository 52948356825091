function GalleryOverview() {
  return (
    <>
      <div className="breadcrumbs-wrap">
        <div className="container">
          <h1 className="page-title">Galerija</h1>
          <ul className="breadcrumbs">
            <li>
              <a href="/">Početna</a>
            </li>
            <li>Početna</li>
          </ul>
        </div>
      </div>
      <div id="content" className="page-content-wrap">
        <div className="container">
          <div className="portfolio-holder content-element5">
            <div className="flex-row item-col-3">
              <div className="project">
                <div className="project-image">
                  <img src="/images/360x286_img1.jpg" alt=""/>
                  <a
                    href="/images/360x286_img1.jpg"
                    className="project-link"
                    data-fancybox="gallery"
                  />
                </div>
              </div>
              <div className="project">
                <div className="project-image">
                  <img src="/images/360x286_img2.jpg" alt=""/>
                  <a
                    href="/images/360x286_img2.jpg"
                    className="project-link"
                    data-fancybox="gallery"
                  />
                </div>
              </div>
              <div className="project">
                <div className="project-image">
                  <img src="/images/360x286_img3.jpg" alt=""/>
                  <a
                    href="/images/360x286_img3.jpg"
                    className="project-link"
                    data-fancybox="gallery"
                  />
                </div>
              </div>
              <div className="project">
                <div className="project-image">
                  <img src="/images/360x286_img4.jpg" alt=""/>
                  <a
                    href="/images/360x286_img4.jpg"
                    className="project-link"
                    data-fancybox="gallery"
                  />
                </div>
              </div>
              <div className="project">
                <div className="project-image">
                  <img src="/images/360x286_img5.jpg" alt=""/>
                  <a
                    href="/images/360x286_img5.jpg"
                    className="project-link"
                    data-fancybox="gallery"
                  />
                </div>
              </div>
              <div className="project">
                <div className="project-image">
                  <img src="/images/360x286_img6.jpg" alt=""/>
                  <a
                    href="/images/360x286_img6.jpg"
                    className="project-link"
                    data-fancybox="gallery"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="align-center">
            <ul className="pagination">
              <li>
                <a href="#" className="prev-page"/>
              </li>
              <li className="active">
                <a href="#">1</a>
              </li>
              <li>
                <a href="#" className="next-page"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default GalleryOverview;
