import { useEffect, useState } from 'react';

function Header() {
  const fixedHeaderPages = [
    '/', // Landing page
  ];

  // Figuring out if page should use fixed header or not
  const [isFixedHeaderPage, setIsFixedHeaderPage] = useState(true);

  useEffect(() => {
    const pageHasFixedHeader = fixedHeaderPages.includes(window.location.pathname);
    setIsFixedHeaderPage(pageHasFixedHeader);
  }, []);

  return (
    <>
      {/* - - - - - - - - - - - - Mobile Menu - - - - - - - - - - - - - - */ }
      <nav id="mobile-advanced" className="mobile-advanced"/>
      {/* - - - - - - - - - - - - - Header - - - - - - - - - - - - - - - - */ }
      <header id="header" className={ 'header sticky-header ' + (isFixedHeaderPage ? 'fixed-header' : ' ') }
      >
        {/* searchform */ }
        <div className="searchform-wrap">
          <div className="vc-child h-inherit">
            <form className="search-form">
              <button type="submit" className="search-button"/>
              <div className="wrapper">
                <input type="text" name="search" placeholder="Start typing..."/>
              </div>
            </form>
            <button className="close-search-form"/>
          </div>
        </div>
        {/* top-header */ }
        <div className="top-header">
          <div className="flex-row align-items-center justify-content-between">
            {/* logo */ }
            <div className="logo-wrap">
              <a href="/" className="logo">
                <img src="/images/logo.png" alt=""/>
              </a>
            </div>
            {/* - - - - - - - - - - - / Mobile Menu - - - - - - - - - - - - - */ }
            {/*main menu*/ }
            <div className="menu-holder">
              <div className="menu-wrap">
                <div className="nav-item">
                  {/* - - - - - - - - - - - - - Navigation - - - - - - - - - - - - - - - - */ }
                  <nav id="main-navigation" className="main-navigation">
                    <ul id="menu" className="clearfix">
                      <li className="current">
                        <a href="/">Početna</a>
                      </li>
                      <li>
                        <a href="#">Studio</a>
                        {/*sub menu*/ }
                        <div className="sub-menu-wrap">
                          <ul>
                            <li>
                              <a href="/studio/o-nama">O nama</a>
                            </li>
                            {/*<li>*/}
                            {/*  <a href="/studio/tim">Naš tim</a>*/}
                            {/*</li>*/}
                            <li>
                              <a href="/studio/faq">Često postavljena pitanja</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <a>Časovi</a>
                        {/*sub menu*/ }
                        <div className="sub-menu-wrap">
                          <ul>
                            <li>
                              <a href="/casovi/vrste-casova">Vrste časova</a>
                            </li>
                            <li>
                              <a href="/casovi/vrste-yoge">Vrste yoge</a>
                            </li>
                            <li>
                              <a href="/casovi/raspored">Raspored</a>
                            </li>
                            <li>
                              <a href="/casovi/cenovnik">Cenovnik</a>
                            </li>
                            {/*<li>*/}
                            {/*  <a href="/casovi/izazovi">Izazovi</a>*/}
                            {/*</li>*/}
                          </ul>
                        </div>
                      </li>
                      <li>
                        <a href="/studio/kontakt">Kontakt</a>
                      </li>
                      {/*<li>*/}
                      {/*  <a href="/dogadjaji">Dogadjaji</a>*/}
                      {/*</li>*/}
                      <li>
                        <a href="/galerija">Galerija</a>
                      </li>
                      {/*<li>*/}
                      {/*  <a>Blog</a>*/}
                      {/*  /!*sub menu*/ }
                      {/*  <div className="sub-menu-wrap">*/}
                      {/*    <ul>*/}
                      {/*      <li>*/}
                      {/*        <a href="/blog">Sve objave</a>*/}
                      {/*      </li>*/}
                      {/*      <li>*/}
                      {/*        <a href="/blog/vezbanje">Vežbanje</a>*/}
                      {/*      </li>*/}
                      {/*      <li>*/}
                      {/*        <a href="/blog/ishrana-zdrav-zivot">Ishrana i zdrav život</a>*/}
                      {/*      </li>*/}
                      {/*    </ul>*/}
                      {/*  </div>*/}
                      {/*</li>*/}
                      {/*<li>*/}
                      {/*  <a>Prodavnica</a>*/}
                      {/*  /!*sub menu*/ }
                      {/*  <div className="sub-menu-wrap">*/}
                      {/*    <ul>*/}
                      {/*      <li>*/}
                      {/*        <a href="/shop">Svi artikli</a>*/}
                      {/*      </li>*/}
                      {/*      <li>*/}
                      {/*        <a href="/shop/korpa">Korpa</a>*/}
                      {/*      </li>*/}
                      {/*      <li>*/}
                      {/*        <a href="/shop/porudzbine">Moje porudzbine</a>*/}
                      {/*      </li>*/}
                      {/*    </ul>*/}
                      {/*  </div>*/}
                      {/*</li>*/}
                    </ul>
                  </nav>
                  {/* - - - - - - - - - - - - end Navigation - - - - - - - - - - - - - - - */ }
                </div>
                {/* search button */ }
                <div className="search-holder">
                  <button type="button" className="search-button"/>
                </div>

                {/*/!* account button */ }
                {/*<button type="button" className="account popup-btn-login"/>*/}
                {/*/!* shop button */ }
                {/*<div className="shop-cart">*/}
                {/*  <button className="sc-cart-btn dropdown-invoker">*/}
                {/*    <span className="licon-cart"/>*/}
                {/*  </button>*/}
                {/*  <div className="shopping-cart dropdown-window">*/}
                {/*    <div className="products-holder">*/}
                {/*      <div className="product">*/}
                {/*        <button className="item-close"/>*/}
                {/*        <a href="#" className="product-image">*/}
                {/*          <img src="/images/78x78_img1.jpg" alt=""/>*/}
                {/*        </a>*/}
                {/*        <div className="product-description">*/}
                {/*          <h6 className="product-title">*/}
                {/*            <a href="#">Strunjača</a>*/}
                {/*          </h6>*/}
                {/*          <span className="product-price">1x$19.95</span>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*      <div className="product">*/}
                {/*        <button className="item-close"/>*/}
                {/*        <a href="#" className="product-image">*/}
                {/*          <img src="/images/78x78_img2.jpg" alt=""/>*/}
                {/*        </a>*/}
                {/*        <div className="product-description">*/}
                {/*          <h6 className="product-title">*/}
                {/*            <a href="#">Yoga Blokovi</a>*/}
                {/*          </h6>*/}
                {/*          <span className="product-price">1x12.25EUR</span>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*    <div className="sc-footer">*/}
                {/*      <div className="subtotal">*/}
                {/*        Total: <span className="total-price">35EUR</span>*/}
                {/*      </div>*/}
                {/*      <div className="vr-btns-set">*/}
                {/*        <a href="#" className="btn btn-small">*/}
                {/*          Pogledaj Korpu*/}
                {/*        </a>*/}
                {/*        <a href="#" className="btn btn-small btn-style-3">*/}
                {/*          Plaćanje*/}
                {/*        </a>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <a href="/studio/kontakt" className="btn popup-btn btn-big btn-style-3">
                  Zakaži čas
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
